
<template>
  <div class="mobile">
    <!-- 顶部 -->
    <div class="header Flex-Sb-Ce FlexVC">
      <img class="h-logo" src="~@/assets/img/logo.png" alt="" />
      <p class="h-title">{{ Object.is(lang, "cn")?'积分商城':'Integral mall'}}</p>
      <div class="Flex-Sb-Ce">
        <img
          :class="['h-lang', { 'btn-lang': lang == 'en' }]"
          src="~@/assets/img/lang.png"
          @click="changeLang"
          alt=""
        />
        <a :href="`tel:${phone}`">
          <img class="h-phone" src="~@/assets/img/phone.png" alt=""
        /></a>
      </div>
    </div>
    <!-- 主体 -->
    <div class="container">
      <p class="supplierName">{{Object.is(lang, "cn")?'供应商':'Vendor'}}:{{supplierName}}</p>
      <!-- 搜索框 -->
      <div class="Flex-Sb-Ce search-box">
        <input
          v-model="searchFields.goods_name"
          @keydown.enter="onSearch"
          type="text"
          :placeholder="Object.is(lang, 'cn')?'请输入您要搜索的商品关键字':'Please enter the keyword of the item you want to search'"
          autocomplete="off"
        />
        <img
          @click="onSearch"
          class="img-search"
          alt=""
          src="~@/assets/img/search.png"
        />
      </div>

      <!-- 轮播 -->
      <div class="swiper-container swiper-container-banner">
        <div class="swiper-wrapper" v-if="bannerList.length">
          <div
            class="swiper-slide"
            v-for="item of bannerList"
            :key="item.bannerId"
          >
            <div class="imgback">
              <img class="imgback" :src="item.banner" />
            </div>
          </div>
        </div>
         <div class="swiper-wrapper" v-else>
          <div
            class="swiper-slide"
          >
            <div class="imgback">
              <img class="imgback" :src="Object.is(lang, 'cn') ? imgCn :imgEn" />
            </div>
          </div>
        </div>
        <!-- <div class="swiper-pagination swiper-pagination-banner"></div> -->
      </div>
      <ul class="labelList FlexPuls">
        <li
          :class="{ 'btn-active': isActive == index }"
          @click="changeNav(index, item)"
          v-for="(item, index) of allCategory"
          :key="item.id"
        >
          {{ Object.is(lang, "cn") ? item.typeNameCn : item.typeName }}
        </li>
      </ul>
      <!-- <div
        :class="['typeNav', ' Flex-Sb-Ce', { fixed: isFixed }]"
        ref="typebox"
      >
        <ul class="t-type">
          <li class="type-active">
            <span>食品类型</span><span class="triangle">▲</span>
          </li>
        </ul>
        <ul class="t-label">
          <li><span>食品标签</span><span class="triangle">▲</span></li>
        </ul>
      </div> -->
      <!-- 商品 -->
      <ul class="goodslist Flex-Wr-Jc">
        <li class="goods-item" v-for="(item, index) of renderArr" :key="index">
          <div class="goods-msg">
            <!-- require(`../../assets/img/goods/64597.jpg`) -->
            <img
              class="img-goods"
              :src="item.goodsImp"
              :alt="Object.is(lang, 'cn') ? item.goodsNameCn : item.goodsName"
            />
            <div class="goods-num">{{Object.is(lang, "cn") ? "商品编号" : "Item Number" }}: {{ item.goodsCode }}</div>
          </div>
          <div class="goods-b">
            <div class="goods-name">
              {{ Object.is(lang, "cn") ? item.goodsNameCn : item.goodsName }}
            </div>
            <div class="Flex-Sb-Ce">
              <div class="goods-point">
                <span class="color-red">{{ item.points / 100 }} </span
                >{{ Object.is(lang, "cn") ? "积分" : "Points" }}
              </div>
              <span class="goods-qua"
                >{{ item.unit
                }}{{
                  Object.is(lang, "cn") ? item.dictionCn : item.dictionEn
                }}</span
              >
            </div>
          </div>
        </li>
        <!-- <li  v-if="renderArr.length">{{Object.is(lang,'cn'?'无搜索结果！':'No search results!')}}</li> -->
      </ul>
      <div :class="['loading', { block: isLoading || loadingText }]">
        <img
          v-if="!loadingText"
          class="imgloading"
          src="~@/assets/img/loading.gif"
          alt=""
        />
        <p v-else>{{ loadingText }}</p>
      </div>
    </div>
    <img
      src="~@/assets/img/goback.png"
      :class="['goback', { block: isFixed }]"
      @click="goback"
    />
  </div>
</template>

<script>
import imgCn from '../../assets/img/back.jpg'
import imgEn from '../../assets/img/back1.jpg'
export default {
  name: "Mobile",
  data() {
    return {
      imgCn,
      imgEn,
      supplierName:'',
      initialSlide:0,
      phone: "",
      lang: "cn",
      isActive: 0, //控制导航栏选中状态
      isFixed: false,
      renderArr: [], //渲染商品数组
      // pageSize: 0,
      isLoading: false,
      loadingText: null,
      allCategory: [],
      searchFields: {
        //搜索条件
        goods_name: "",
        supplier_id: "",
        goods_type: "",
      },
      bannerList: [], //轮播
      pageSize: 10,
      startPage: 1,
      total: 0,
    };
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.startPage++;
        setTimeout(() => {
          this.getMpGoods();
        }, Math.random() * 1500);
      }
    },
  },
  methods: {
    //中英文切换
    async changeLang() {
      (await Object.is(this.lang, "cn"))
        ? (this.lang = "en")
        : (this.lang = "cn");
      await this.getMpBanners();
      this.initialSlide=0
    },
    //获取商品种类
    getMpCategorys() {
       let params = {
         pageSize: -1,
        searchFields:{ supplier_id: this.searchFields.supplier_id },
      };
      this.$services
        .getMpCategorys(params)
        .then((res) => {
          if (res.code == 200) {
            this.allCategory.push(
              {
                id: "",
                typeNameCn: "全部",
                typeName: "All",
              },
              ...res.t.records
            );
          }
        });
    },
    //获取供应商信息
    getMpSuppliers() {
      let params = {
        searchFields: { supplier_id: this.searchFields.supplier_id },
      };
      this.$services.getMpSuppliers(params).then((res) => {
        if (res.code === 200) {
          this.phone = res.t.records[0].contactPhone;
          this.supplierName = res.t.records[0].supplierName;
        }
      });
    },
    // 获取轮播
    getMpBanners() {
      let params = {
        pageSize: -1,
        searchFields: {
          supplier_id: this.searchFields.supplier_id,
          client_type: 2, //1pc 2app
          language: this.lang == "cn" ? 1 : 2, //1中文 2英文
          status:1
        },
        startPage: 0,
      };

      this.$services.getMpBanners(params).then(async (res) => {
        if (res.code == 200) {
          // await this.bannerList.length = 0;
          await this.bannerList.splice(0, this.bannerList.length);
          await this.bannerList.push(...res.t.records);
          this.swiperBanner();
        }
      });
    },
    //获取商品数据
    getMpGoods() {
      let params = {
        pageSize: this.pageSize,
        searchFields: {
          [this.lang == "cn" ? "goods_name_cn" : "goods_name"]: this
            .searchFields.goods_name,
          supplier_id: this.searchFields.supplier_id,
          goods_type: this.searchFields.goods_type,
        },
        startPage: this.startPage,
      };
      this.$services.getMpGoods(params).then((res) => {
        // console.log(res);
        if (res.code === 200) {
          this.renderArr.push(...res.t.records);

          this.total = res.t.total;
          this.isLoading = false;
        }
      });
    },
    onSearch() {
      this.startPage = 1;
      this.renderArr = [];
      this.getMpGoods();
    },
    // 切换商品类型
    changeNav(index, item) {
      this.isActive = index;
      this.searchFields.goods_type = item.id;
      this.startPage = 1;
      this.renderArr = [];
      this.getMpGoods();
    },
    // 回到顶部
    goback() {
      window.scrollTo(0, 0);
    },
    // 下拉到底加载数据
    onScroll() {
      // let typeOffsetTop = this.$refs.typebox.offsetTop;
      const current =
          document.documentElement.scrollTop || document.body.scrollTop,
        clientH = window.innerHeight,
        scrollH =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      // current >= typeOffsetTop ? (this.isFixed = true) : (this.isFixed = false);
      if (
        current + clientH === scrollH &&
        this.total != this.renderArr.length
      ) {
        this.isLoading = true;
      }
      this.total == this.renderArr.length
        ? (this.loadingText = Object.is(this.lang,'en')?"到底了！":'到底了！')
        : (this.loadingText = null);
    },
    swiperBanner() {
      new this.Swiper(".swiper-container-banner", {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed:1500,
        initialSlide:this.initialSlide
        // pagination: {
        //   el: ".swiper-pagination-banner",
        //   clickable: true,
        // },
      });
    },
  },

  async mounted() {
    this.searchFields.supplier_id = this.$route.params.id ||5;
    this.getMpCategorys();
    this.getMpGoods();
    this.getMpSuppliers();
    this.getMpBanners();
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.block {
  display: block !important;
}
.fixed {
  position: fixed !important;
  top: 10.317460317460318vw !important;
  z-index: 9999 !important;
}
.btn-lang {
  transform: rotate(180deg);
}
.btn-active {
  color: #1f93ff !important;
  font-weight: bold !important;
  border-bottom: 0.7vw solid #1f93ff !important;
}
.type-active {
  color: #1f93ff !important;
  font-weight: bold !important;
}
.loading {
  width: 100%;
  text-align: center;
  display: none;
  padding-bottom: 1.3333333333333333vw;
  color: #666;
}
.imgloading {
  width: 7.333333333333333vw;
}
.goback {
  bottom: 8vw;
  position: fixed;
  z-index: 99999;
  right: 4vw;
  width: 8vw;
  height: 8vw;
  line-height: 8vw;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 50%;
  display: none;
}
.triangle {
  transform: rotateX(180deg);
  display: inline-block;
  margin-left: 2.6666666666666665vw;
}
.mobile {
  width: 100%;
}
.mobile .header {
  position: fixed;
  width: 92%;
  height: 8vw;
  padding: 1.8666666666666667vw 4vw;
  background: linear-gradient(90deg, #1fdedb, #5b80e9);
  z-index: 99999;
}
.mobile .header .h-logo {
  width: 9.066666666666666vw;
}
.mobile .header .h-phone {
  width: 6.566667vw;
}
.mobile .header .h-lang {
  width: 6.566667vw;
  margin-right: 1vw;
  transition: 0.5s;
}

.mobile .header .h-title {
  font-size: 4.8vw;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.mobile .container {
  background-image: linear-gradient(to bottom, #fff, #f2f2ff);
  padding: 2.6666666666666665vw 4vw;
  padding-top: 15vw;
}
.mobile .container .search-box {
  width: 98%;
  border-radius: 20px;
  background: #f2f2f2;
  margin-bottom: 2.6666666666666665vw;
  padding: 0.8666666666666666vw;
}
.mobile .container input {
  border: none;
  background: transparent;
  outline: none;
  text-indent: 1em;
  width: 100%;
  height: 100%;
}
.mobile .container .img-search {
  width: 6.566667vw;
  margin-right: 0.6666666666666666vw;
}
.mobile .container .imgback {
  display: block;
  width: 100%;
  height: 40vw;
  border-radius: 1.3333333333333333vw;
  margin-bottom: 4.533333333333333vw;
}
.mobile .container .labelList {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
  height: 9.733333vw;
}
.mobile .container .labelList li {
  font-size: 4.533333333333333vw;
  font-weight: 400;
  color: #111111;
  margin-right: 8.933333333333334vw;
  white-space: nowrap;
}
.mobile .container .typeNav {
  width: 100%;
  height: 10.317460317460318vw;
  background: #ffffff;
}
.mobile .container .typeNav ul {
  flex: 0 0 50%;
}
.mobile .container .typeNav ul li {
  font-size: 3.7333333333333334vw;
  font-weight: 500;
  color: #666666;
}
.mobile .container .goodslist {
  width: 100%;
  margin-top: 1.3333333333333333vw;
}
.mobile .container .goodslist .goods-item {
  width: 41.666667vw;
  height: 62vw;
  margin-bottom: 2.6666666666666665vw;
  background: #fff;
  box-shadow: 0.26666666666666666vw 0.4vw 0.5333333333333333vw 0px
    rgba(121, 122, 127, 0.2);
  border-radius: 1.3333333333333333vw;
  padding: 1.3333333333333333vw;
}
.mobile .container .goodslist .goods-item .goods-msg {
  position: relative;
  border-radius: 0.6666666666666666vw;
  overflow: hidden;
}
.mobile .container .goodslist .goods-item .goods-msg .img-goods {
  width: 100%;
  height: 42vw;
}
.mobile .container .goodslist .goods-item .goods-msg .goods-num {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6.666666666666667vw;
  line-height: 6.666666666666667vw;
  background: #a9d1f6;
  border-radius: 0.6666666666666666vw;
  font-size: 2.933333333333333vw;
  font-weight: bold;
  color: #111111;
  text-align: center;
}

.mobile .container .goodslist .goods-item .goods-b {
  padding: 3.8666666666666667vw 2.6666666666666665vw;
}
.mobile .container .goodslist .goods-item .goods-b .goods-name {
  width: 100%;
  height: 3.066666666666667vw;
  font-size: 3.066666666666667vw;
  font-weight: bold;
  color: #111111;
  line-height: 3.066666666666667vw;
  margin-bottom: 4vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mobile .container .goodslist .goods-item .goods-b .goods-point {
  font-size: 4vw;
  font-weight: bold;
}
.color-red {
  color: #ec3338;
}
.mobile .container .goodslist .goods-item .goods-b .goods-qua {
  font-size: 2.6666666666666665vw;
  font-weight: bold;
  color: #111111;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 4.7333333333333335vw;
}
.supplierName{
  margin-bottom: 2.3333333333333333vw;
}
</style>